import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';
import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';

export const adapter: EntityAdapter<IProfessionSpecialtyEntity> =
  createEntityAdapter<IProfessionSpecialtyEntity>({});
export const messageableFactory =
  MessageableFactory.forFeature<'ProfessionSpecialty'>('ProfessionSpecialty');
export const signalableFactory =
  SignalableFactory.forFeature<'ProfessionSpecialty'>('ProfessionSpecialty');
export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const loadingAdapter = createLoadingStateAdapter<'ProfessionSpecialty'>(messageableFactory);

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedProfessionSpecialtyId: null,
});
