export enum FilterNamespace {
  passport = 'passport',
  default = 'default',
}
export interface IFilterGroup {
  displayName: string;
  slug: string;
  count: number;
  filterCategories?: IFilterCategory[];
  options?: IOption[];
  hasPreferredProfession?: boolean;
  namespace: FilterNamespace;
  notes?: string;
  optional?: boolean;
}

export interface IFilterCategory {
  id: number;
  displayName: string;
  selected: boolean;
  count: number;
  filterOptions: IFilterOption[];
}

export interface IFilterOption {
  displayName: string;
  count: number;
  options: IOption[];
}

export interface IOption {
  id: number;
  parentId: number;
  category?: number;
  name: string;
  order: number;
  selected: boolean;
  visible: boolean;
}

export interface IOptionPayload {
  id: number;
  parentId: number;
  selected: boolean;
  filterOptionName: string;
  filterCategoryId: number;
  filterGroupName: string;
}

export interface IFilterCategoryPayload {
  id: number;
  selected: boolean;
  filterGroupName: string;
}

export interface IFilterSelectAllPayload {
  selected: boolean;
  filterGroup: string;
  showDepartmentSection: boolean;
}
