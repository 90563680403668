import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { get, isNil, uniq } from 'lodash-es';
import { combineLatest, map, Observable, switchMap } from 'rxjs';

import { ISelectOption } from '@locumsnest/components/src';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import {
  IProfessionSpecialtyAttributes,
  IProfessionSpecialtyEntity,
} from '../../interfaces/api/profession-specialty-entity';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { IProfessionSpecialtyService } from '../../profession/+state/interfaces/interfaces';
import { ProfessionService } from '../../profession/+state/profession.service';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import {
  ProfessionSpecialtyPaginationMessages,
  ResetProfessionSpecialtyPaginationMessage,
  UpsertMultipleMessage,
  UpsertMultiplePagesMessage,
  UpsertProfessionSpecialtyPageMessage,
} from './profession-specialty.messages';
import { ProfessionSpecialtyPersistenceService } from './profession-specialty.persistence.service';
import {
  professionSpecialtyPaginationSelectors,
  selectAllProfessionSpecialtyIds,
  selectProfessionSpecialtyEntityState,
  selectProfessionSpecialtyIds,
} from './profession-specialty.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfessionSpecialtyService
  extends FilterStateService<
    IProfessionSpecialtyEntity,
    UpsertProfessionSpecialtyPageMessage,
    ResetProfessionSpecialtyPaginationMessage,
    UpsertMultipleMessage
  >
  implements IProfessionSpecialtyService
{
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: ProfessionSpecialtyPersistenceService,
    private subSpecialtyService: SubSpecialtyService,
    private professionService: ProfessionService,
    protected microAppService: MicroAppService,
  ) {
    super();
  }

  get paginationMessages() {
    return ProfessionSpecialtyPaginationMessages;
  }

  get upsertMessageClass() {
    return UpsertProfessionSpecialtyPageMessage;
  }
  get clearMessageClass() {
    return ResetProfessionSpecialtyPaginationMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get upsertMultiplePagesMessage() {
    return UpsertMultiplePagesMessage;
  }

  get paginationSelectors() {
    return professionSpecialtyPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfessionSpecialtyEntityState;
  }
  getAllProfessionSpecialtyIds() {
    return this.store.pipe(select(selectAllProfessionSpecialtyIds));
  }

  getSpecialtyIdsForProfessions(professionIds): Observable<number[]> {
    return this.store.pipe(select(selectProfessionSpecialtyIds(professionIds)));
  }

  getAllForProfessions(professions) {
    return this.getAll().pipe(
      map((professionSpecialties: IProfessionSpecialtyEntity[]) =>
        professionSpecialties.filter((professionSpecialty) =>
          professions.includes(professionSpecialty.profession),
        ),
      ),
    );
  }

  getAllFiltersAfterLoading(): Observable<IProfessionSpecialtyEntity<number, number>[]> {
    return super
      .getAllFiltersAfterLoading()
      .pipe(map((filters) => filters.filter(({ active }) => active)));
  }

  getAllActiveWithDetailsForProfessions(professions: number[]) {
    return this.getAllActiveWithDetails().pipe(
      map(
        (
          professionSpecialties: IProfessionSpecialtyEntity<
            ISubSpecialtyEntity,
            IProfessionEntity
          >[],
        ) =>
          professions.filter((x) => !isNil(x)).length > 0
            ? professionSpecialties.filter((professionSpecialty) =>
                !isNil(professionSpecialty) && professionSpecialty.profession
                  ? professions.includes(professionSpecialty.profession.id)
                  : [],
              )
            : [],
      ),
    );
  }

  getActiveByProfessionId(professionId: number) {
    return this.getAllActive().pipe(
      map((professionSpecialties) =>
        professionSpecialties.filter((x) => x.profession === professionId),
      ),
    );
  }

  getActiveIdsByProfessionId(professionId: number) {
    return this.getActiveByProfessionId(professionId).pipe(
      map((entities) => entities.map(({ id }) => id)),
    );
  }

  getActiveByProfessionIdList(professionIdList: number[]) {
    return this.getAllActive().pipe(
      map((professionSpecialties) =>
        professionSpecialties.filter((x) => professionIdList.includes(x.profession)),
      ),
    );
  }

  getActiveOptionsByProfessionIds(ids: number[]): Observable<ISelectOption[]> {
    return this.getAllActiveWithDetailsForProfessions(ids).pipe(
      map((professionSpecialties) =>
        professionSpecialties.map((ps) => ({ id: ps.id, name: ps.specialty.title })),
      ),
      map((res) => res.sort((a, b) => a.name.localeCompare(b.name))),
    );
  }

  getActiveIdsByProfessionIdList(professionIdList: number[]) {
    return this.getActiveByProfessionIdList(professionIdList).pipe(
      map((entities) => entities.map(({ id }) => id)),
    );
  }

  getActiveCategoriesByProfessionId(professionId: number) {
    return this.getActiveByProfessionId(professionId).pipe(
      concatLatestFrom(() => this.subSpecialtyService.getAll()),
      map(([professionSpecialties, subSpecialties]) => {
        const categoryIds = professionSpecialties
          .map((professionSpecialty) =>
            get(
              subSpecialties.find(
                (subSpecialty) => subSpecialty.id === professionSpecialty.specialty,
              ),
              'category',
            ),
          )
          .filter((specialtyId) => !!specialtyId);
        return uniq(categoryIds);
      }),
    );
  }

  getActiveCategoriesByProfessionIdList(professionIdList: number[]) {
    return this.getActiveByProfessionIdList(professionIdList).pipe(
      concatLatestFrom(() => this.subSpecialtyService.getAll()),
      map(([professionSpecialties, subSpecialties]) => {
        const categoryIds = professionSpecialties
          .map((professionSpecialty) =>
            get(
              subSpecialties.find(
                (subSpecialty) => subSpecialty.id === professionSpecialty.specialty,
              ),
              'category',
            ),
          )
          .filter((specialtyId) => !!specialtyId);
        return uniq(categoryIds);
      }),
    );
  }

  getOneByAttributes({ profession, specialty }: IProfessionSpecialtyAttributes) {
    return this.getAllAfterLoading().pipe(
      map((professionSpecialties) =>
        professionSpecialties.find(
          (professionSpecialty) =>
            professionSpecialty.profession === profession &&
            professionSpecialty.specialty === specialty,
        ),
      ),
    );
  }

  getProfessionId(professionSpecialtyId: number) {
    return this.getOne(professionSpecialtyId).pipe(
      map((professionSpecialty) => professionSpecialty.profession),
    );
  }

  getOneWithSpecialty(
    professionSpecialtyId: number,
  ): Observable<IProfessionSpecialtyEntity<ISubSpecialtyEntity, number>> {
    return this.getOne(professionSpecialtyId).pipe(
      switchMap((professionSpecialty) =>
        this.subSpecialtyService.getOne(professionSpecialty.specialty).pipe(
          map((specialty) => ({
            ...professionSpecialty,
            specialty,
          })),
        ),
      ),
    );
  }

  getAllActiveWithSpecialty(): Observable<
    IProfessionSpecialtyEntity<ISubSpecialtyEntity, number>[]
  > {
    return this.getAllActive().pipe(
      switchMap((professionSpecialties) =>
        this.subSpecialtyService.getAll().pipe(
          map((subSpecialties) =>
            professionSpecialties.map((professionSpecialty) => {
              const specialty = subSpecialties
                ? subSpecialties.find((x) => x.id === professionSpecialty.specialty)
                : null;

              return {
                ...professionSpecialty,
                specialty,
              };
            }),
          ),
        ),
      ),
    );
  }

  getAllWithProfession(): Observable<IProfessionSpecialtyEntity<number, IProfessionEntity>[]> {
    //AG: based on invariant that we always have profession specialties
    return this.getAll().pipe(
      switchMap((professionSpecialties) =>
        combineLatest(
          professionSpecialties.map((professionSpecialty) =>
            this.professionService.getOne(professionSpecialty.profession).pipe(
              map((profession) => ({
                ...professionSpecialty,
                profession,
              })),
            ),
          ),
        ),
      ),
    );
  }

  getAllActive() {
    return this.getAll().pipe(
      map((professionSpecialties) => professionSpecialties.filter(({ active }) => !!active)),
    );
  }
  getOneWithDetails(
    id,
  ): Observable<IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>> {
    return this.getOne(id).pipe(
      switchMap((professionSpecialty) => this.getDetails(professionSpecialty)),
    );
  }

  getDetails(
    professionSpecialty: IProfessionSpecialtyEntity,
  ): Observable<IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>> {
    return combineLatest([
      this.professionService.getOne(professionSpecialty.profession),
      this.subSpecialtyService.getOne(professionSpecialty.specialty),
    ]).pipe(
      map(([profession, specialty]) => ({
        ...professionSpecialty,
        profession,
        specialty,
      })),
    );
  }
  addCategory(
    professionSpecialty: IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>,
  ) {
    return this.subSpecialtyService.addCategory(professionSpecialty.specialty).pipe(
      map((specialty) => ({
        ...professionSpecialty,
        specialty,
      })),
    );
  }

  getAllActiveWithDetails(): Observable<
    IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>[]
  > {
    return this.getAllActive().pipe(
      switchMap((professionSpecialties) =>
        combineLatest(
          professionSpecialties.map((professionSpecialty) => this.getDetails(professionSpecialty)),
        ),
      ),
    );
  }

  getByCategoryId(categoryId, professionId) {
    return this.getActiveByProfessionId(professionId).pipe(
      concatLatestFrom(() => this.subSpecialtyService.getAll()),
      map(([professionSpecialties, subSpecialties]) =>
        professionSpecialties.filter(
          (professionSpecialty) =>
            get(
              subSpecialties.find(
                (subSpecialty) => subSpecialty.id === professionSpecialty.specialty,
              ),
              'category',
            ) === categoryId,
        ),
      ),
    );
  }

  fetch() {
    return this.loadAllPages('default', null);
  }
}
