import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';
import { paginationAdapter } from './profession-specialty.adapter';
export const { UpsertPageMessage, UpsertMultiplePagesMessage, ResetPaginationMessage } =
  paginationAdapter.getMessages();

export const ProfessionSpecialtyPaginationMessages = paginationAdapter.getMessages();

export class UpsertProfessionSpecialtyPageMessage extends UpsertPageMessage {}
export class UpsertProfessionSpecialtyMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetProfessionSpecialtyPaginationMessage extends ResetPaginationMessage {}

export enum ProfessionSpecialtySignalTypes {
  LOAD_PROFESSION_SPECIALTIES = '[ProfessionSpecialty][Signal] Load Collection',
}
export enum ProfessionSpecialtyMessageTypes {
  SET_COLLECTION = '[ProfessionSpecialty][Message] Set Collection',
  ADD_ONE = '[ProfessionSpecialty][Message] Add One',
  UPDATE_ONE = '[ProfessionSpecialty][Message] Update One',
  UPSERT_ONE = '[ProfessionSpecialty][Message] Upsert One',
  DELETE_ONE = '[ProfessionSpecialty][Message] Delete One',
  ADD_MULTIPLE = '[ProfessionSpecialty][Message] Add All',
  DELETE_MULTIPLE = '[ProfessionSpecialty][Message] Delete Many',
  UPSERT_MULTIPLE = '[ProfessionSpecialty][Message] Upsert Many',
  UPDATE_MULTIPLE = '[ProfessionSpecialty][Message] Update Many',
}
export class SetCollectionMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IProfessionSpecialtyEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IProfessionSpecialtyEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IProfessionSpecialtyEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IProfessionSpecialtyEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IProfessionSpecialtyEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IProfessionSpecialtyEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IProfessionSpecialtyEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = ProfessionSpecialtyMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export type ProfessionSpecialtyMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
