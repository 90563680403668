import { Injectable } from '@angular/core';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfessionSpecialtyEntity } from '../../interfaces/api/profession-specialty-entity';

@Injectable({
  providedIn: 'root',
})
export class ProfessionSpecialtyPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IProfessionSpecialtyEntity
> {
  protected readonly endpoint = 'professionSpecialty';
}
