import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  IProfessionSpecialtyEntityState,
  IProfessionSpecialtyFeatureState,
  IProfessionSpecialtySharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './profession-specialty.adapter';
import { selectAll } from './profession-specialty.reducer';

export const getEntityState = (state: { entityState: IProfessionSpecialtyEntityState }) =>
  state.entityState;

export const getAllProfessionSpecialtyIds = (state: IProfessionSpecialtyEntityState) =>
  (state.ids as number[]).map((id: number) => state.entities[id].specialty);

export const getProfessionSpecialtyIds =
  (professionIds: number[]) => (state: IProfessionSpecialtyEntityState) =>
    (state.ids as number[])
      .filter((id) => professionIds.includes(state.entities[id].profession))
      .map((id: number) => state.entities[id].specialty);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectProfessionSpecialtyState = createFeatureSelector<
  IProfessionSpecialtyFeatureState & IProfessionSpecialtySharedState
>('professionSpecialties');

export const selectProfessionSpecialtyEntityState = createSelector(
  selectProfessionSpecialtyState,
  getEntityState
);

export const selectAllProfessionSpecialties = createSelector(
  selectProfessionSpecialtyEntityState,
  selectAll
);

export const selectAllProfessionSpecialtyIds = createSelector(
  selectProfessionSpecialtyEntityState,
  getAllProfessionSpecialtyIds
);

export const selectProfessionSpecialtyIds = (professionIds: number[]) =>
  createSelector(selectProfessionSpecialtyEntityState, getProfessionSpecialtyIds(professionIds));

export const loadingStateSelectors = loadingAdapter.getSelectors(selectProfessionSpecialtyState);

export const professionSpecialtyPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfessionSpecialtyState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
